import { Button } from "@mui/material";
import React from "react";
import ScheduleCallThumbnail from "../Assets/Images/video-thumb-cropped.jpg";

const ScheduleCall = (props) => {
  return (
    <div className="schedule-container">
      <div className="thumbnail">
        <img src={ScheduleCallThumbnail} alt="Alan George" srcSet="" />
      </div>
      <h2>Schedule a free call with Alan</h2>
      <p>Schedule a call with Alan to debrief the results of your test and take the next step toward building your team's digital ministry strategy!</p>
      <div className="cta-btn">
        <a title="Calendly Digital Ministry Matrix" href={`https://calendly.com/reachalangeorge/digitalministrymatrix?utm_source=${props?.id?.trim()}`} target="_blank" rel="noreferrer">
          <Button>TALK TO ALAN</Button>
        </a>
      </div>
    </div>
  )
}


export default ScheduleCall;

import React, { useRef, useEffect } from 'react';

import { Link, useSearchParams } from "react-router-dom";

import useWindowDimensions from '../Hooks/useWindowDimensions';
import VideoThumbnail from '../Assets/Images/video-thumb-cropped.jpg';
import StartMobileImg from '../Assets/Images/start-mobile.png';
import StartDesktopImg from '../Assets/Images/start-desktop.png';
import PlayButton from '../Assets/Images/play-button.png';
import Clock from '../Assets/Images/clock.png';

import '../App.scss';

const Start = props => {
  const { windowWidth }        = useWindowDimensions();
  const [isLoading, setIsLoading]         = React.useState(true);

  const [searchParams, setSearchParams]   = useSearchParams();
  const [playingVideo, setPlayingVideo]   = React.useState(false);
  const [startPlaying, setStartPlaying]   = React.useState(false);
  searchParams.get("leader");
  const vidRef = useRef(null);

  useEffect(() => { 
		// document.body.style.backgroundColor = "#122845";
	},[]);

  const assessmentLink = () => {
    if(searchParams == "") {
      return "/assessment";
    } else {
      return "/assessment?" + searchParams;
    }
  }

  const playVideo = (e) => {
    console.log(vidRef.current);
    vidRef.current.play();
    setPlayingVideo(true);
    if(!startPlaying) {
      setStartPlaying(true);
    }
  }

  const pauseVideo = (e) => {
    console.log(vidRef.current);
    vidRef.current.pause();
    setPlayingVideo(false);
  }

  const showThumbnail = () => {
    if(playingVideo) {
      return <img className="thumbnail" src={VideoThumbnail} style={{ opacity: '0'}} onClick={(e) => pauseVideo(e)} />;
    } else {
      if(startPlaying) {
        return null
      } else {
        return <img className="thumbnail" src={VideoThumbnail} />
      }
    }
  }

  const showPlayButton = () => {
    if(playingVideo) {
      return null
    } else {
      return <img className="playbtn" src={PlayButton} onClick={(e) => playVideo(e)} />
    }
  }

  const vidsrcURL = "";
  
  return (
    <div className="container start">
      {/* <div className="video"> */}
      <div className="img-container">
        { windowWidth <= 830 && <img className="start-img-mobile" src={StartMobileImg} alt="" srcSet="" /> }
        { windowWidth > 830 && <img className="start-img-desktop" src={StartDesktopImg} alt="" srcSet="" /> }
      </div>
        {/* <video ref={vidRef}>
          <source
            src="https://40parables-assets.s3.amazonaws.com/Bleat-Digital-Ministry-Matrix-v2.mp4"
            type="video/mp4"
            />
        </video> */}
        {/* {showThumbnail()}
        {showPlayButton()} */}
      {/* </div> */}
      <div className="intro">
        <h1>Is your church on track with its digital ministry?</h1>
        <h2>Discover where you are on the journey toward leveraging technology to transform lives in your church & community!</h2>
        <div className="completion">
          <Link to={assessmentLink()} className="cta">
            Get Started
          </Link>
          <div className='time-estimate'>
            <img className='clock-icon' src={Clock} alt="" /> <span>3 min</span>
          </div>
        </div>
        {/* <div className="completion">Estimated time for completion: 3 min</div> */}
      </div>
    </div>
  );
}
export default Start;